import {Component, inject, OnInit} from '@angular/core';
import {LoginModalComponent} from '../../../portal/@portal-theme/components/login-modal/login-modal.component';
import {JWTService, NewAuthUser} from '../../../@core/jwt.service';

@Component({
    selector: 'app-switch-user-modal',
    templateUrl: './switch-user-modal.component.html',
    host: {'data-test-id': 'switch-user-modal'},
})
export class SwitchUserModalComponent extends LoginModalComponent implements OnInit {
    newUser: NewAuthUser;

    private jwt = inject(JWTService);

    handleRefresh() {
        window.location.reload();
    }

    ngOnInit() {
        super.ngOnInit();

        this.jwt.newUser$.subscribe(newUser => {
            this.newUser = newUser;
        });
    }
}
