<div class="modal-header">
    <h4 class="modal-title" id="modal-title">User has changed</h4>
</div>
<div class="alert alert-warning m-0 rounded-0">
    A new user has logged in on a different tab. Please log in again if you want to continue with the previous user, or click the button to switch to the new user.
</div>
<!--This should be the new user-->
<button class="btn btn-block btn-light d-flex align-items-center p-3 rounded-0 strong" *ngIf="newUser" (click)="handleRefresh()">
    <span class="mr-2">Continue with</span>
    <app-user-icon [user]="newUser" [hasTooltip]="false"></app-user-icon>
    <span class="ml-2">{{newUser.email}}</span>
    <fal i="chevron-right" class="ml-auto"></fal>
</button>
<div class="modal-body">
    <div class="strong mb-3">Or log in to continue with</div>
    <div *ngIf="errorMessage || retries < 3" class="alert alert-danger">
        <ng-container *ngIf="errorMessage">{{errorMessage}}</ng-container>
        <strong>Remaining attempts: {{retries}}</strong>
    </div>
    <form>
        <div class="form-group">
            <label class="control-label w-100">{{configService.loginUserLabel}}</label>
            <input [(ngModel)]="email" name="email" type="email" class="form-control-plaintext strong" readonly>
        </div>
        <div class="form-group">
            <label class="control-label w-100">Password</label>
            <input [(ngModel)]="password"
                   name="password"
                   type="password"
                   class="form-control"
                   ngbAutoFocus
                   (keyup.enter)="handleLogin()"
                   [disabled]="reAuthRequest.isPending"
                   data-test-id="login-modal-password">
        </div>
    </form>
</div>
<div class="modal-footer d-flex justify-content-end">
    <button type="button"
            class="btn btn-primary"
            (click)="handleLogin()"
            [disabled]="!email || !password"
            [appUnderProcessing]="reAuthRequest.isPending"
            data-test-id="login-modal-login-btn">
        Log In
    </button>
</div>
